import { Button } from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { AiFillCopy, AiOutlineDelete } from "react-icons/ai";
import { IoIosPricetag } from "react-icons/io";
import { LuCalendarCheck } from "react-icons/lu";
import { useLocation, useNavigate } from "react-router-dom";
import CustomSingleSlider from "../../components/_customcarSlider/CustomSingleSlider";
import PaymentModal from "../../components/modal/HidePayment";
import ProductListskeleton from "../../components/skeleton/ProductListskeleton";
import Productskeleton from "../../components/skeleton/Productskeleton";
import useMyContext from "../../context/useMyContext";
import { publicRequest } from "../../utils/requestMethods";
import { _getNewProduct } from "../../utils/requestUtils";
import NotFoundPage from "../notfound/NotFoundPage";
import { pictureSrcForProduct, pictureSrcUpdate } from "../../utils/imgsrc-utils";
import parse from 'html-react-parser';


const ProductCar = () => {

  const location = useLocation();
  const [, , , lot] = location.pathname.split("/");

  const navigate = useNavigate();
  const [swtichImage, setSwitchImage] = useState(0);
  const [singleProductData, setSingleProductData] = useState(null);
  const [similarData, setSimilarData] = useState([]);
  const PA = process.env.REACT_APP_ASSETS_BASE_URL_WITHOUT_SLASH;
  const { pathname } = useLocation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [error, setError] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const handleCopyVin = async () => {
    if (singleProductData?.vin) {
      await navigator.clipboard.writeText(singleProductData?.vin)
        .then(() => {
          console.log('Text copied to clipboard successfully');
        })
        .catch((error) => {
          console.error('Error copying text to clipboard:', error);
        });

    }
  };

  useEffect(() => {
    async function getProduct() {
      try {
        const productById = await publicRequest.get("/public/product/check/" + lot);

        setSingleProductData(productById.data);
      } catch (err) {

        setError(true)
      }
    }

    getProduct()

  }, [lot]);



  const handleSwitchImage = (index) => {
    setSwitchImage(index);
  };


  const { carId, updateData } = useMyContext();

  let makes = "";
  let model = "";
  let year = "";
  if (singleProductData?.name) {

    const pattern = /^(\d{4}) (\w+) (.+), (.+)$/;
    const match = singleProductData?.name.match(pattern);

    year = match ? match[1] : '';
    makes = match ? match[2] : '';
    model = match ? `${match[3]}, ${match[4]}` : '';
  }



  useEffect(() => {
    if (openModal) {
      updateData(singleProductData?.id)
    }
  }, [openModal])





  // useEffect(() => {
  //   try {
  //     _getNewProduct().then(data => setSimilarData(data))
  //   } catch (err) {

  //   }
  // }, [])


  const ltdJsonList = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    "itemListElement": [
      {
        "@type": "ListItem",
        "position": 1,
        "name": "Automobile",
        "item": "https://checkedvin.com/products/vehicle"
      },
      {
        "@type": "ListItem",
        "position": 2,
        "name": `${singleProductData?.tag ? singleProductData?.tag : singleProductData?.name?.replace(/ /g, '-')}`,
        "item": `https://checkedvin.com/product/vehicle/${singleProductData?.lot}/${singleProductData?.tag?.replace(/ /g, '-') ? singleProductData?.tag : singleProductData?.name?.replace(/ /g, '-')}`
      }
    ]
  }
  const ltdJson = {
    "@context": "https://schema.org",
    "@type": "Vehicle",
    "url": `https://checkedvin.com/product/vehicle/${singleProductData?.lot}/${singleProductData?.tag?.replace(/ /g, '-') ? singleProductData?.tag : singleProductData?.name?.replace(/ /g, '-')}`,
    "name": `${singleProductData?.name?.replace(/ /g, '-')} | ${singleProductData?.vin} | Checkedvin`,
    "description": `${singleProductData?.name?.replace(/ /g, '-')} ✔️ VIN: ${singleProductData?.vin} Lot: ${singleProductData?.lot}  `,
    "vehicleIdentificationNumber": `${singleProductData?.vin}`,
    "sku": `${singleProductData?.vin}`,
    "mpn": `${singleProductData?.vin}`,
    "image": pictureSrcUpdate(singleProductData),
    "offers": {
      "@type": "Offer",
      "url": `https://checkedvin.com/product/vehicle/${singleProductData?.lot}/${singleProductData?.tag?.replace(/ /g, '-') ? singleProductData?.tag : singleProductData?.name?.replace(/ /g, '-')}`,
      "itemCondition": "https://schema.org/UsedCondition",
      "availability": "https://schema.org/InStock",
      "priceSpecification": {
        "@type": "PriceSpecification",
        "price": singleProductData?.final_bid_formatted  ? singleProductData?.final_bid_formatted?.replace("$", "") : "9999",
        "priceCurrency": "USD"
      },
    }
  }
  return (
    <div className="SingleProduct bg-[#d1d5db] p-5 flex flex-col items-center justify-center overflow-hidden">

      {(!singleProductData?.vin && !error) && <>

        <Productskeleton />
      </>}
      {error && <NotFoundPage />}

      {singleProductData?.vin && (<>
        <div className="p-10 ">
          <Helmet>

            <meta property="og:type" content={"website"} />
            <meta property="og:url" content={`https://checkedvin.com/product/vehicle/${singleProductData?.lot}/${singleProductData?.tag ? singleProductData?.tag?.replace(/ /g, '-') : singleProductData?.name?.replace(/ /g, '-')}`} />
            <meta property="og:title" content={`${singleProductData?.name?.replace(/ /g, '-')} | ${singleProductData?.vin} | Checkedvin`} />
            <meta property="og:description" content={`${singleProductData?.name?.replace(/ /g, '-')} ✔️ VIN: ${singleProductData?.vin} Lot: ${singleProductData?.lot} ➡️   `} />
            <meta property="og:image" content={pictureSrcUpdate(singleProductData)} />


            <meta property="twitter:card" content={"summary_large_image"} />
            <meta property="twitter:url" content={`https://checkedvin.com/product/vehicle/${singleProductData?.lot}/${singleProductData?.tag ? singleProductData?.tag?.replace(/ /g, '-') : singleProductData?.name?.replace(/ /g, '-')}`} />
            <meta property="twitter:title" content={`${singleProductData?.name?.replace(/ /g, '-')} | ${singleProductData?.vin} | Checkedvin`} />
            <meta property="twitter:description" content={`${singleProductData?.name?.replace(/ /g, '-')} ✔️ VIN: ${singleProductData?.vin} Lot: ${singleProductData?.lot} ➡️   `} />
            <meta property="twitter:image" content={pictureSrcUpdate(singleProductData)} />


            <title>{`${singleProductData?.name.replace(/ /g, '-')}| ${singleProductData?.vin} | Checkedvin`}</title>
            <meta name="title" content={`${singleProductData?.name.replace(/ /g, '-')} | ${singleProductData?.vin} | Checkedvin`} />
            <meta name="description" content={`${singleProductData?.name.replace(/ /g, '-')} ✔️ VIN: ${singleProductData?.vin} Lot: ${singleProductData?.lot}  | Odometer: ${singleProductData?.odometer ? singleProductData?.odometer : singleProductData?.odometer_to_string}`} />
            <link rel="alternate" hreflang="en" href={`https://checkedvin.com/product/vehicle/${singleProductData?.lot}/${singleProductData?.tag ? singleProductData?.tag?.replace(/ /g, '-') : singleProductData?.name?.replace(/ /g, '-')}`} />
            <link rel="alternate" hreflang="x-default" href={`https://checkedvin.com/product/vehicle/${singleProductData?.lot}/${singleProductData?.tag ? singleProductData?.tag?.replace(/ /g, '-') : singleProductData?.name?.replace(/ /g, '-')}`} />
            <link rel="canonical" href={`https://checkedvin.com/product/vehicle/${singleProductData?.lot}/${singleProductData?.tag ? singleProductData?.tag?.replace(/ /g, '-') : singleProductData?.name?.replace(/ /g, '-')}`} />


            <script type="application/ld+json">
              {JSON.stringify(ltdJsonList)}
            </script>
            <script type="application/ld+json">
              {JSON.stringify(ltdJson)}
            </script>

          </Helmet>
          <h1 className="text-[19px] p-3">{singleProductData?.name}</h1>
          <div className="md:flex gap-5">
            <CustomSingleSlider
              item={singleProductData}
              currentImage={swtichImage}
            />
            <div className="grid  grid-cols-3 md:grid-cols-3 w-full h-[40%] md:w-[35%] md:h-[20%] gap-x-1 gap-y-[2px] pt-6">
              {singleProductData?.imgUrls?.map((item, index) => (
                <img
                  key={index}
                  onClick={() => handleSwitchImage(index)}
                  className="w-[150px] h-[70px] sm:w-[170px] sm:h-[110px]  border rounded-[8px] transition-all duration-300 hover:border-gray-500 "
                  alt={`${singleProductData?.name}slider ${index + 1}`}
                  src={pictureSrcForProduct(singleProductData, item)}
                />
              ))}
            </div>
          </div>
          <div className="flex flex-col md:flex-row pt-4">
            <div className="sm:pt-4 text-[10px] bg-[#263238] text-[#a8a8a8] p-4 md:rounded-l-xl shadow-lg w-full  md:w-[60%] sm:text-sm">
              <h1 className="p-2 text-xl">Car Info</h1>
              <div className="pt-2 p-3 m-1 gap-3 flex justify-between  border-b  border-[#0d1d25]">
                <span>Vin</span>
                <span className="flex items-center ">
                  {singleProductData?.vin} <AiFillCopy onClick={handleCopyVin} cursor={"pointer"} />
                </span>
              </div>
              <div className="pt-2 p-3 m-1  flex justify-between  border-b  border-[#0d1d25]">
                <span>Odometer</span>
                <span>{singleProductData?.odometer ? singleProductData?.odometer + "(mi)" : singleProductData?.odometer_to_string}</span>
              </div>
              {singleProductData?.body_style && <div className="pt-2 p-3 m-1  flex justify-between border-b  border-[#0d1d25] ">
                <span>BodyStyle</span>
                <span>{singleProductData?.body_style}</span>
              </div>
              }
              {singleProductData?.primary_damage && <div className="pt-2 p-3 m-1  flex justify-between border-b  border-[#0d1d25] ">
                <span>Primary Damage</span>
                <span>{singleProductData?.primary_damage}</span>
              </div>}
              {singleProductData?.secondary_damage && <div className="pt-2 p-3 m-1  flex justify-between   border-b  border-[#0d1d25]">
                <span>Secondary Damage</span>
                <span>{singleProductData?.secondary_damage}</span>
              </div>}
              {singleProductData?.key_type && <div className="pt-2 p-3 m-1  flex justify-between border-b  border-[#0d1d25] ">
                <span>Key</span>
                <span>{singleProductData?.key_type?.toUpperCase()}</span>
              </div>}
              {singleProductData?.engine && <div className="pt-2 p-3 m-1  flex justify-between border-b  border-[#0d1d25] ">
                <span>Engine</span>
                <span>{singleProductData?.engine}</span>
              </div>}
              {year &&
                <div className="pt-2 p-3 m-1 flex justify-between border-b  border-[#0d1d25]  ">
                  <span>Date </span>
                  <span>{year}</span>
                </div>
              }


              {singleProductData?.fuel_type && (
                <div className="pt-2 p-3 m-1 flex justify-between border-b  border-[#0d1d25]">
                  <span>Fuel </span>
                  <span>{singleProductData?.fuel_type}</span>
                </div>
              )}
              {singleProductData?.start_code && <div className="pt-2 p-3 m-1  flex justify-between border-b  border-[#0d1d25] ">
                <span>Start Code </span>
                <span>{singleProductData?.start_code}</span>
              </div>
              }
              {(makes || singleProductData?.makes) && <div className="pt-2 p-3 m-1  flex justify-between border-b  border-[#0d1d25] ">
                <span>Makes </span>
                <span>{singleProductData?.makes ? singleProductData?.makes : makes}</span>
              </div>
              }
              {(model || singleProductData?.makes) && <div className="pt-2 p-3 m-1  flex justify-between  border-b  border-[#0d1d25]">
                <span>Models </span>
                <span>{singleProductData?.model ? singleProductData?.model : model}</span>
              </div>
              }
              {singleProductData?.drive_type && <div className="pt-2 p-3 m-1 flex justify-between  border-b  border-[#0d1d25] ">
                <span>Drive </span>
                <span>{singleProductData?.drive_type}</span>
              </div>}
              {singleProductData?.cylinders && <div className="pt-2 p-3 m-1  flex justify-between  border-b  border-[#0d1d25] ">
                <span>Cylindres </span>
                <span>{singleProductData?.cylinders}</span>
              </div>
              }
            </div>
            {isModalOpen && <PaymentModal carId={singleProductData?.id} closeModal={closeModal} />}
            <div className="bg-[#080b11ec] text-[10px] sm:text-[16px] rounded-r-xl w-[70%] h-[250px] md:w-[50%] md:h-[250px] p-5 ">

              <div onClick={openModal} className="flex  items-center gap-3 bg-red-800 p-2 w-[70%] rounded-md justify-center m-auto cursor-pointer">
                <AiOutlineDelete size="30" color="white" />
                <span className="text-white text-[15px]">Hide Car Information</span>
              </div>
              <span className="text-[#c4c4c4] border-b border-[#536168] text-xl">
                More Info
              </span>
              {singleProductData?.prebid_close_time &&
                <div className=" flex justify-between p-3 pt-2 text-white">
                  <span className="flex gap-3 align-items">  <LuCalendarCheck size="20" /> Auction Date </span>
                  <span>{singleProductData?.prebid_close_time}</span>
                </div>
              }
              <div className="flex   pt-5 p-3 text-[#a8a8a8]  justify-between">
                <h3 className="flex gap-3 items-center"><IoIosPricetag size="20" /> Lot </h3>
                <span className=" flex items-center gap-x-3">
                  {singleProductData?.lot} <AiFillCopy cursor={"pointer"} />
                </span>
              </div>
              <div className="text-[#a8a8a8] flex gap-3 p-3">
                <h2>Final Price</h2>
                <span>

                  {singleProductData?.final_bid_formatted === "0" || singleProductData?.final_bid_formatted === "---" || !singleProductData?.final_bid_formatted
                    ? "No Inforamtion"
                    : "$" + singleProductData?.final_bid_formatted}
                </span>
              </div>
            </div>
          </div>
        </div>

        {singleProductData?.text_generation_check && <div className=" w-[80%] md:flex flex-col  justify-center items-center gap-2 p-2" >
          <p>{parse(singleProductData?.text_generation_dcv)}</p>
        </div>}

        {/* <div className="p-10 pt-2 ">
          <h1>Similar Products</h1>

          <div className="grid grid-cols-2 md:grid-cols-3 2xl:grid-cols-5  place-content-center gap-x-20 gap-y-15 w-[80vw]">
            {similarData.length === 0 && (
              Array.from({ length: 10 }).map((_, index) => (
                <div key={index}>
                  <ProductListskeleton />
                </div>
              ))
            )}

            {similarData?.map((item, index) => (
              <div
                key={item.id}
                className="newCars-Container items-center  p-1 flex flex-col gap-2"
              >
          

                <div className="rounded-[6px] sm:rounded-xl w-[130px] h-[100px] sm:w-[200px] sm:h-[150px] overflow-hidden relative">
                  <div
                    className="flex transition-all h-full duration-500"

                  >

                    <img

                      src={pictureSrcUpdate(item)}
                      alt={`${item.vin}_${item.name}_slider_1`}
                      className=""
                    />

                  </div>
                </div>

                <p className="whitespace-nowrap text-elipsis w-1/2 overflow-hidden text-[14px]">{item?.name}</p>
                <p className="whitespace-nowrap">{item?.final_bid_formatted === "0" || item?.final_bid_formatted === "---" || !item?.final_bid_formatted ? "No Information" : "$ " + item?.final_bid_formatted}</p>
                <Button onClick={() => window.open(`/product/vehicle/${item.lot}/${item?.tag ? item?.tag?.replace(/ /g, '-') : item?.name?.replace(/ /g, '-')}`)}>View More</Button>
              </div>
            ))}
          </div>

        </div>
         */}
        
        </>)}
    </div>
  );
};

export default ProductCar;
